.Mui-datatable-user div div table tbody tr td div span svg {
  background: white;
  // border-radius: 5px;
}

.paragraph-padding-remove p {
  margin-bottom: 5px;
}

.invitation-links,
.invitation-card {
  padding: 20px 50px;
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  // border-radius: 20px;
  border: 1px solid #f3f3f3;
}

.invitation-links {
  max-width: 320px;
}

.invitation-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invitation-title p {
  color: #374151;
  font-size: 18px;
}

.invitation-card {
  margin-top: 50px;
}
