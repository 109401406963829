@import "~react-pro-sidebar/dist/scss/styles.scss";

.sidebar {
  height: 100%;
  position: relative;
  display: flex;
}

.sidebar-footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}
// .txt:hover {
//   text-decoration: underline;
// }
.pro-sidebar-layout {
  box-shadow: -5px 0px 25px 0px rgba(0, 0, 0, 0.2);
}

.sidebar-footer,
.sidebar-header {
  background-color: rgb(48, 74, 231) !important;
  color: white;
}

.pro-sidebar-layout {
  background-color: white;
  color: rgb(48, 74, 231);
}

.pro-sidebar .pro-inner-item:hover {
  background-color: unset !important;
  color: unset !important;
}

.pro-inner-item {
  display: inline-block;
  position: relative;
}

.active-link {
  // border-bottom: 2px solid blue;
  box-shadow: 0px 2px 3px 1px #888888;
}

.pro-inner-item:hover {
  // border-bottom: 2px solid blue;
  box-shadow: 0px 1px 5px 1px #888888;

}

.pro-inner-item {
  transition: transform 0.25s ease-in-out;
}

// .pro-inner-item::after {
//   content: '';
//   position: absolute;
//   width: 100%;
//   transform: scaleX(0);
//   height: 2px;
//   bottom: 0;
//   left: 0;
//   background-color: blue;
//   transform-origin: bottom right;
//   transition: transform 0.25s ease-out;
// }

// .pro-inner-item:hover::after {
//   transform: scaleX(1);
//   transform-origin: bottom left;
// }

.pro-item-content a {
  color: rgb(48, 74, 231) !important;
}

.title {
  font-size: 20px !important;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.sidebar-username {
  margin-left: 20px;
}
