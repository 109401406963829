.home-main {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  position: relative;
}

.home-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(48, 74, 231, 0.5);
  z-index: 1;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/WPS_BGR COLOR 02.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.button {
  position: absolute;
  z-index: 2;
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 15px 30px;
  // border-radius: 5px;
  font-size: 18px;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #fff;
  color: rgb(48, 74, 231);
  cursor: pointer;
}

.home-box {
  z-index: 3;
  margin: 0 200px;
}

.home-box .card {
  background-color: #ffffffde;
}

.home-box-image {
  min-width: 100px;
  max-width: 100px;
  max-height: 100px;
  min-height: 100px;
  margin-right: 20px;
  // border-radius: 50%;
  overflow: hidden;
}

.home-box-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.home-box span {
  color: #5b9bd5;
  font-weight: bold;
}

.home-box-body {
  display: flex;
}

.quote-body-container {
  max-height: 500px;
  overflow-y: auto;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 1200px) {
  .home-box {
    margin: 0 100px;
  }
}

@media (max-width: 900px) {
  .home-box {
    margin: 0 50px;
  }
}

@media (max-width: 700px) {
  .home-box {
    margin: 0;
    overflow-y: auto;
  }
  .home-box-body {
    flex-direction: column;
    gap: 20px;
  }
}
