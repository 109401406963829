.ticket-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ticket-background-color {
  background-color: #f5f5f5;
}

.ticket-box {
  display: inline-block;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  // border-radius: 10px;
  border: 1px solid #ececec; /* Add a light border */
  // border-radius: 10px;
  font-family: "Montserrat", sans-serif;
}

h2 {
  margin: 0;
}

.user-info p {
  font-size: 16px;
  margin: 5px 0;
}

.qrcode svg {
  height: 100%;
  width: 100%;
  max-width: 200px;
}

.user-profile .card {
  // border-radius: 10px;
}

.user-profile .card .card-header .profile_img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 10px auto;
  border: 10px solid #ccc;
  // border-radius: 50%;
}

.user-profile .card h3 {
  font-size: 20px;
  font-weight: 700;
}

.user-profile .card p {
  font-size: 16px;
  color: #000;
}

.user-profile .table th,
.user-profile .table td {
  font-size: 14px;
  padding: 5px 10px;
  color: #000;
}

.table-width th {
  width: 30%;
}

@media (max-width: 465px) {
  .hide-qr {
    display: none;
  }
  .qr-card-box {
    border: none !important;
    background-color: #f5f5f5 !important;
  }
}

@media (max-width: 992px) {
  .user-info-top {
    margin-top: 20px;
  }
}
