.register {
  position: relative;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../../assets/images/WPS_BGR COLOR 01.png');
  height: 100%;
  gap: 40px;
}

.register-delegation {
  height: fit-content;
  overflow-y: hidden !important;
}

.register-swiper {
  display: flex;
  align-items: center;
  position: relative;
}

.register-btns {
  display: flex;
  justify-content: center;
  align-self: center;
}

.register-btns,
.register-btns button {
  width: 100%;
}

.register-btns,
.register-card {
  max-width: 550px;
  min-width: 250px;
}

.delegation-name{
  background-color: #ffffff;
  // border-radius: 30px;
  padding: 20px;
  margin-bottom: 30px;
}

.delegation-next-name{
  width: 100%;
}

.register-card {
  // border-radius: 50px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  height: fit-content;
  padding: 30px 0;
  position: relative;
}

.register-card-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: #212529;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
}

.register-card-body {
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.register-button,
.register-button-back {
  padding: 1em;
  color: white;
  border: none;
  // border-radius: 30px;
  font-weight: 600;
  width: 100%;
}

.register-button {
  background: #3e4684;
}

.register-button-back {
  background: #c42d2d;
}

.new-registration-card {
  background-color: #ffffff;
  max-width: 300px;
  height: fit-content;
  padding: 20px;
  // border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 20px;
}

@media (max-width: 576px) {
  .register-card-body .col-sm-6:first-child {
    margin-bottom: 15px;
  }
  .register {
    padding: 20px;
  }
}

@media (max-width: 575px) {
  .register-delegation {
    height: fit-content;
  }
}

@media (max-width: 576px) and (max-height: 850px) {
  .register {
    height: auto;
  }
}

@media (max-width: 400px) {
  .register-card-body {
    padding: 0 25px;
  }
}

#custom-select-label {
  background-color: #fff;
  padding: 0 7px !important;
}

.register-close-btn {
  position: absolute;
  top: 0;
  right: 0;
}
