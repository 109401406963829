// .login {
//   background-color: #dde5f4;
// }

.login {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-image: url('../../assets/images/WPS_BGR COLOR 01.png');
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.registration-container {
  flex-shrink: 0;
}

.card-style-below-registration {
  // border-radius: 20px !important;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
  background: #f1f7fe;
}

.card-style {
  // border-radius: 50px !important;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
  background: #f1f7fe;
  width: 100%;
  width: 450px;
  flex-basis: 450px;
  flex-shrink: 0;
}

.icon-color {
  color: #3e4684;
}

.resend-button {
  padding: 1em;
  background: gray !important;
  color: white;
  border: none;
  // border-radius: 30px;
  font-weight: 600;
  width: 100%;
}

.screen-1 .login-button {
  padding: 1em;
  background: #3e4684;
  color: white;
  border: none;
  // border-radius: 30px;
  font-weight: 600;
  width: 100%;
}

.screen-1 {
  padding: 10px 2em;
  display: flex;
  flex-direction: column;
  // border-radius: 30px;
  gap: 2em;
}

.screen-1 .logo {
  margin-top: -3em;
}

.screen-1 .email,
.screen-1 .password {
  background: white;
  box-shadow: 0 0 2em #e6e9f9;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  // border-radius: 20px;
  color: #4d4d4d;
  outline: none;
  border: none;
  width: 300px;
}

.align-right {
  margin-left: auto;
}

.screen-1 .email input {
  outline: none;
  border: none;
  width: 100%;
}

.screen-1 .email input::-moz-placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .email input:-ms-input-placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .email input::placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .email ion-icon {
  color: #4d4d4d;
  margin-bottom: -0.2em;
}

.screen-1 .password input {
  outline: none;
  border: none;
}

.screen-1 .password input::-moz-placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .password input:-ms-input-placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .password input::placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .password ion-icon {
  color: #4d4d4d;
  margin-bottom: -0.2em;
}

.screen-1 .password .show-hide {
  margin-right: -5em;
}

.row-1 .col-md-12 {
  padding-top: 15px;
}

.row-1 .col-md-12 input {
  background-color: white;
}

.swiper-wrapper {
  align-items: center;
} 

.confirmEmail {
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  color: blue;
}

.confirmEmail:hover {
  text-decoration: underline;
}

.fit-modal .modal-dialog {
  max-width: fit-content !important;
}
.resend-verification-button{
  padding: 1em;
  background: #3e4684;
  color: white;
  border: none;
  // border-radius: 30px;
  font-weight: 600;
}

.resend-description {
  padding: 20px 70px;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  max-width: 780px;
  width: 100%;
  line-height: normal;
}

.login-card-title {
  padding: 25px 0 5px 35px !important;
  color: #000;
  font-size: 24px !important;
  font-style: normal !important;
  line-height: normal;
}

.card-padding {
  padding: 35px 40px !important;
}

.success-login-card-padding {
  padding: 25px 30px 45px 30px !important;
}