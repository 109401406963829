.login {
  position: relative;
  background-image: url("../../../../public/images/loginbg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.login::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/images/WPS_BGR COLOR 01.png");
  backdrop-filter: blur(5px);
}

.icon-color {
  color: #3e4684;
}

.resend-button {
  padding: 1em;
  background: red !important;
  color: white;
  border: none;
  // border-radius: 30px;
  font-weight: 600;
  width: 100%;
}

.screen-1 .login-button {
  padding: 1em;
  background: #3e4684;
  color: white;
  border: none;
  // border-radius: 30px;
  font-weight: 600;
  width: 100%;
}

.screen-1 {
  padding: 10px 2em;
  display: flex;
  flex-direction: column;
  // border-radius: 30px;
  gap: 2em;
}

.screen-1 .logo {
  margin-top: -3em;
}

.screen-1 .email,
.screen-1 .password {
  background: white;
  box-shadow: 0 0 2em #e6e9f9;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  // border-radius: 20px;
  color: #4d4d4d;
  outline: none;
  border: none;
  width: 300px;
}

.align-right {
  margin-left: auto;
}

.screen-1 .email input {
  outline: none;
  border: none;
  width: 100%;
}

.screen-1 .email input::-moz-placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .email input:-ms-input-placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .email input::placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .email ion-icon {
  color: #4d4d4d;
  margin-bottom: -0.2em;
}

.screen-1 .password input {
  outline: none;
  border: none;
}

.screen-1 .password input::-moz-placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .password input:-ms-input-placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .password input::placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .password ion-icon {
  color: #4d4d4d;
  margin-bottom: -0.2em;
}

.screen-1 .password .show-hide {
  margin-right: -5em;
}

.row-1 .col-md-12 {
  padding-top: 15px;
}

.row-1 .col-md-12 input {
  background-color: white;
}
