.custom-modal {
  .modal-content {
    // border-radius: 10px;
  }
  .modal-header {
    background-color: #ee9613;
    color: #fff;
    border-bottom: none;
  }
  .modal-body {
    padding: 20px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  .form-label {
    font-weight: bold;
  }
  .row-agenda .col-md-6 {
    padding-bottom: 25px;
  }
  
}
