body {
  margin: 0;
  height: 100vh;
}

#root {
  height: 100%;
}

.main {
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
  min-height: 100vh;
}

.spinner {
  --clr: rgb(46, 51, 189);
  --gap: 6px;
  /* gap between each circle */
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the spinner */
  display: flex;
  justify-self: center;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
}

.datatable-tooltip {
  display: flex;
  justify-content: space-around;
  transform: translateX(-18px);
}

.spinner span,
.componentSpinner span {
  width: 20px;
  height: 20px;
  // border-radius: 100%;
  background-color: var(--clr);
  opacity: 0;
}

.spinner span:nth-child(1),
.componentSpinner span:nth-child(1) {
  animation: fade 1s ease-in-out infinite;
}

.spinner span:nth-child(2),
.componentSpinner span:nth-child(2) {
  animation: fade 1s ease-in-out 0.33s infinite;
}

.spinner span:nth-child(3),
.componentSpinner span:nth-child(3) {
  animation: fade 1s ease-in-out 0.66s infinite;
}

.spinner-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }
}

.custom-select .css-13cymwt-control {
  border: none;
}

.flag-a {
  margin-left: 18px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.flag-text {
  padding-left: 15.5px;
}

.language-arrow{
  position: absolute;
  left: 90px;
  color: black;
}